<template>
    <div class="sidebar bg-base-color min-h-screen overflow-x-auto w-[110px]">
        <div class="sidebar-wrapper">
            <ul class="nav flex text-white flex-col py-4 px-1">

              <router-link to="/dashboard" class="nav-item" active-class="active">
                <div class="nav-link">
                  <i class="fa-solid fa-table-cells-large nav-link-icon"/>
                  <p>{{$t('sidebar.dashboard')}}</p>
                </div>
              </router-link>

              <router-link to="/pledge-monitoring" class="nav-item" active-class="active">
                <div class="nav-link">
                  <i class="fa-solid fa-chart-column nav-link-icon -mr-2"/>
                  <p>Гаров мониторинги</p>
                </div>
              </router-link>

            </ul>
        </div>

    </div>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "Sidebar",
  data() {
    return {
    }
  },

  methods: {
    // ...mapActions(["initMenus"])
  },
  mounted() {
    // this.initMenus()
  }
}

</script>
