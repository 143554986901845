import Vue from 'vue'
import Vuelidate from 'vuelidate'

import VueTheMask from 'vue-the-mask'

import App from './App.vue'
import axiosHttp from './axios'
import store from './store'

import TreeSelect from '@riophae/vue-treeselect';
import draggable from 'vuedraggable';
import vue2Dropzone from 'vue2-dropzone';

import CurrencyFilter from './shared/filters/currency';
import {momentBase} from "@/shared/filters/momentFilter";
import {numberFormat} from "@/shared/filters/numberFormat";
import NumericInput from './components/ui/inputs/NumericInput';
import VueNumberFormat from '@coders-tm/vue-number-format'

import '@riophae/vue-treeselect/dist/vue-treeselect.css';

import 'vue2-dropzone/dist/vue2Dropzone.min.css';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all'

import Element from 'element-ui';
import locale_en from 'element-ui/lib/locale/lang/en'
import locale_uz from 'element-ui/lib/locale/lang/uz-UZ'
import locale_ru from 'element-ui/lib/locale/lang/ru-RU'
import './assets/css/main.scss'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/css/element-variables.scss'
import './assets/css/tailwind.css'
import './assets/css/custom-element-ui.scss'

import ErrorMessage from "@/components/ui/ErrorMessage";
import Pagination from "@/components/ui/Pagination";
import Loader from "@/components/ui/Loader";
import i18n from "@/i18n";

Vue.use(Element, {
    locale: i18n.locale === 'uz'
        ? locale_uz
        : i18n.locale === 'ru'
            ? locale_ru
            : i18n.locale === 'en'
                ? locale_en
                : locale_uz
});

Vue.use(Vuelidate);

Vue.use(VueTheMask);

Vue.use(VueNumberFormat)

Vue.component('error-message', ErrorMessage)
Vue.component('pagination', Pagination)
Vue.component('loader', Loader)

Vue.component('tree-select', TreeSelect);
Vue.component('draggable', draggable);
Vue.component('vue-dropzone', vue2Dropzone);
Vue.component('numeric-input', NumericInput);

Vue.filter('currency', CurrencyFilter);
Vue.filter('moment', momentBase);
Vue.filter('numberFormat', numberFormat);

Vue.prototype.$eventBus = new Vue();
Vue.prototype.$mapBus = new Vue();

Vue.prototype.$http = axiosHttp;

Vue.prototype.hasPermissionAction = function (permission) {
    return this.$root.$store.state.permissions.includes(permission)
};

Vue.prototype.hasMenuAction = function (menu) {
    return this.$root.$store.state.menus.includes(menu)
};

Vue.config.productionTip = false;

store.dispatch('keycloak').then()