import { render, staticRenderFns } from "./ErrorMessage.vue?vue&type=template&id=52395d0c&scoped=true&"
import script from "./ErrorMessage.vue?vue&type=script&lang=js&"
export * from "./ErrorMessage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "52395d0c",
  null
  
)

export default component.exports