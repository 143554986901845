<template>
  <div id="app" class="w-full h-full">
    <Loader :loading="mainLoading" v-if="mainLoading" fullscreen target="body" lock/>
    <Admin v-else-if="isLoggedIn"/>
  </div>
</template>

<script>
import {mapState, mapMutations, mapActions} from 'vuex';
import {MAIN_LOADING} from '@/store';
import Login from '@/views/Login';
import Admin from '@/views/Admin';
import Loader from "@/components/ui/Loader";

export default {
  name: 'App',
  props: ["keycloak"],
  components: {
    Loader,
    Login,
    Admin
  },
    computed: {
        ...mapState(['isLoggedIn', "mainLoading"])
    },
    methods: {
        ...mapMutations({
            mainLoadingFn: MAIN_LOADING
        }),
        ...mapActions(["initProfile"])
    },
  created() {
    this.mainLoadingFn(true)
    this.initProfile(this.keycloak);
  }
}
</script>

<style>
</style>
