import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import http from "./axios"

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'base-name',
            redirect: (to) => {
                if (to && to.path && to.path === "/") {
                    return "/dashboard"
                }
            }
        },
        {
            path: '/dashboard',
            name: 'dashboard',
            component: () => import('./views/dashboard/index')
        },
        {
            path: '/contacts',
            name: 'contacts',
            component: () => import('./views/contacts/index')
        },
        {
            path: '/pledge-monitoring',
            name: 'pledge-monitoring',
            component: () => import('@/views/pledge-monitoring/PledgeMonitoringList'),
            meta: {
                permission: 'PLEDGE_MONITORING'
            }
        },
        {
            path: '/pledge-monitoring/:id/credit',
            name: 'pledge-monitoring-credit-list',
            component: () => import('@/views/pledge-monitoring/credit/CreditList.vue'),
            meta: {
                permission: 'PLEDGE_MONITORING_CREDIT'
            }
        },
        {
            path: '/pledge-monitoring/:id/credit/:creditId/pledge',
            name: 'pledge-monitoring-credit-pledge-list',
            component: () => import('@/views/pledge-monitoring/pledge/PledgeList.vue'),
            meta: {
                permission: 'PLEDGE_MONITORING_CREDIT_PLEDGE'
            }
        },
        {
            path: '/pledge-monitoring/application',
            name: 'pledge-monitoring-application',
            component: () => import('@/views/pledge-monitoring/application/Application.vue'),
            meta: {
                permission: 'PLEDGE_MONITORING_APPLICATION'
            }
        },
        {
            path: '/pledge-monitoring/application/:applicationId',
            name: 'pledge-monitoring-application-edit',
            component: () => import('@/views/pledge-monitoring/application/Application.vue'),
            meta: {
                permission: 'PLEDGE_MONITORING_APPLICATION_EDIT'
            }
        }
        /*{
            path: '/pledge-monitoring/:id/item/list',
            name: 'pledge-monitoring-edit-items-list',
            component: () => import('@/views/pledge-monitoring/PledgeMonitoringItemList'),
            meta: {
                permission: 'PLEDGE_MONITORING',
                actionPermission: 'PLEDGE_MONITORING_EDIT'
            }
        },
        {
            path: '/pledge-monitoring/:id/item/new',
            name: 'pledge-monitoring-item-new',
            component: () => import('@/views/pledge-monitoring/item/PledgeMonitoringItemEdit'),
            meta: {
                permission: 'PLEDGE_MONITORING',
                actionPermission: 'PLEDGE_MONITORING_NEW_ITEM'
            }
        },
        {
            path: '/pledge-monitoring/:id/item/edit/:itemId',
            name: 'pledge-monitoring-item-edit',
            component: () => import('@/views/pledge-monitoring/item/PledgeMonitoringItemEdit'),
            meta: {
                permission: 'PLEDGE_MONITORING',
                actionPermission: 'PLEDGE_MONITORING_EDIT_ITEM'
            }
        }*/
    ]
})

router.beforeEach((to, from, next) => {

    if (to.path.split("/")[1] !== from.path.split("/")[1]) {
        store.dispatch("deleteFiltersData")
    }

    next()

    /*if (to.meta.permission && (to.meta?.permission !== from.meta?.permission || to.meta?.actionPermission !== from.meta?.actionPermission)) {
        http.post('permission/user/actions', {code: to.meta.permission})
            .then(res => {
                store.dispatch('setPermission', res.data)
                if (store.state.menus.length > 0) {
                    if (store.state.menus.indexOf(to.meta.permission) >= 0) {
                        to.meta.actionPermission
                            ? res.data.includes(to.meta.actionPermission)
                                ? next()
                                : next(to.path.split("/")[1])
                            : next()
                    } else {
                        next("/dashboard")
                    }
                } else {
                    http.get('/permission/user/menu')
                        .then(menuRes => {
                            if (menuRes && menuRes.data && menuRes.data.menus) {
                                store.dispatch("setMenus", menuRes.data.menus)
                                if (store.state.menus.indexOf(to.meta.permission) >= 0) {
                                    if (to.meta.actionPermission && res.data.includes(to.meta.actionPermission)) {
                                        next()
                                    } else {
                                        next(to.path.split("/")[1])
                                    }
                                } else {
                                    next("/dashboard")
                                }
                            }
                        }).catch((err) => {
                        console.log(err);
                        next("/dashboard")
                    });
                }

            }).catch((err) => {
            console.log(err);
            next("/dashboard")
        })
    } else {
        next()
    }*/

    return next;
});

export default router;