<template>
  <div>
<!--    <div class="global-loader-wrapper" v-if="isGlobalLoading">-->
<!--      <div id="global-loader-target" class="global-loader-target"/>-->
<!--    </div>-->
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "GlobalLoaderIndicator",
  data() {
    return {
      loader: null,
    }
  },
  computed: {
    ...mapState(["isGlobalLoading"])
  },
  methods: {
    startLoading() {
      this.loader = this.$loading({
        fullscreen: true,
        lock: true,
        target: "#global-loader-target",
        customClass: "global-loader-wrapper",
        background: "rgba(0, 0, 0, 0.7)"
      });
    },
    endLoading() {
      this.loader.close();
    }
  },
  watch: {
    isGlobalLoading: function(val) {
      if(val) {
        this.startLoading()
      }
      else {
        this.endLoading();
      }
    }
  },
  destroyed() {
    this.endLoading();
  }
}
</script>

<style scoped>

</style>