import {NumberFormat} from "@coders-tm/vue-number-format";

function numberFormat(value, {
    prefix = '',
    suffix = '',
    separator = ' ',
    decimal = '.',
    precision = 0,
    min = -Infinity,
    max = Infinity,
    nullValue = '',
    reverseFill = false,
    autofocus = false,
    allowNegative = false
} = {}) {
    let formatter = new NumberFormat({
        prefix,
        suffix,
        separator ,
        decimal,
        precision,
        min,
        max,
        nullValue,
        reverseFill,
        autofocus,
        allowNegative
    })
    return formatter.format(value)
}
export {numberFormat}