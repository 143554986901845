<template>
  <div class="el-input el-input--suffix" :class="{'is-error': invalid, 'is-disabled': disabled}">
    <input
        type="text"
        autocomplete="off"
        class="el-input__inner"
        v-model="formattedValue"
        :placeholder="placeholder"
        :autofocus="autofocus"
        :disabled="disabled"
        :readonly="readonly"
        v-number="config"
        @input="handleInput"
    >
    <span class="el-input__suffix" v-if="suffixIconClass">
      <span class="el-input__suffix-inner">
        <i class="el-input__icon" :class="suffixIconClass"/>
      </span>
    </span>
  </div>
</template>

<script>
import { directive as VNumber } from '@coders-tm/vue-number-format'

export default {
  name: "NumericInput",
  props: {
    placeholder: String,
    number: [Number, String],
    prefix: {
      type: String,
      default: ''
    },
    suffix: {
      type: String,
      default: ''
    },
    separator: {
      type: String,
      default: ' '
    },
    decimal: {
      type: String,
      default: '.'
    },
    precision: {
      type: Number,
      default: 0
    },
    min: {
      type: Number,
      default: -Infinity
    },
    max: {
      type: Number,
      default: Infinity
    },
    nullValue: {
      type: String,
      default: ''
    },
    reverseFill: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    allowNegative: {
      type: Boolean,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    suffixIconClass: {
      type: String,
      default: null
    },
    withChangeFn: {
      type: Boolean,
      default: false
    }
  },
  directives: {
    number: VNumber,
  },
  computed: {
    formattedValue: {
      get() {
        return this.$props.number
      },
      set(newValue) {
        this.$emit('update:number', newValue ? parseFloat(newValue.replaceAll(' ', '')) : null)
      }
    }
  },
  data() {
    return {
      config: {
        prefix: this.$props.prefix,
        suffix: this.$props.suffix,
        separator: this.$props.separator,
        decimal: this.$props.decimal,
        precision: this.$props.precision,
        min: this.$props.min,
        max: this.$props.max,
        nullValue: this.$props.nullValue,
        reverseFill: this.$props.reverseFill
      }
    }
  },
  methods: {
    handleInput() {
      if(this.$props.withChangeFn) {
        this.$emit('handle-change')
      }
    }
  }
}
</script>

<style scoped>

</style>