import { Message } from 'element-ui';
import i18n from "@/i18n";

const options = (key, valueKey, onlyMessageText, onlyMessageKey, type, duration = 5000) => {
    return {
        message: onlyMessageText && onlyMessageText.length > 0
                    ? onlyMessageText
                    : onlyMessageKey && onlyMessageKey.length > 0
                        ? i18n.t(onlyMessageKey)
                            : key && key.length > 0
                            ? i18n.t(`common.statusMessages.${type}.${key}`, {value: valueKey
                                    ? i18n.t(valueKey)
                                    : null})
                            : type === 'success'
                                ? 'Success'
                                    : type === 'warning'
                                        ? "Warning"
                                            : type === 'error'
                                                ? "Error"
                                                : "",
        showClose: true,
        duration: duration
    }
}

export function successMessage({key = null, valueKey = null, onlyMessageText = null, onlyMessageKey = null}, duration) {
    Message.success(options(key, valueKey, onlyMessageText, onlyMessageKey, 'success', duration))
}

export function errorMessage({key = null, valueKey = null, onlyMessageText = null, onlyMessageKey = null}, duration) {
    Message.error(options(key, valueKey, onlyMessageText, onlyMessageKey, 'error', duration))
}

export function warningMessage({key = null, valueKey = null, onlyMessageText = null, onlyMessageKey = null}, duration) {
    Message.warning(options(key, valueKey, onlyMessageText, onlyMessageKey, 'warning', duration))
}
