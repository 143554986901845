<template>
    <div class="wrapper">
        <div style="min-height: 100vh">
            <HeaderPanel/>
            <div class="flex relative">
              <sidebar/>
              <div class="main-wrapper w-[calc(100%-110px)]">
                <router-view></router-view>
              </div>
            </div>
        </div>
        <GlobalLoaderIndicator/>
    </div>
</template>
<script>
    import Sidebar from '@/components/layout/Sidebar'
    import HeaderPanel from "@/components/layout/HeaderPanel";
    import GlobalLoaderIndicator from "@/components/ui/GlobalLoaderIndicator.vue"

    export default {
        components: {
            HeaderPanel,
            Sidebar,
            GlobalLoaderIndicator
        }
    }
</script>
