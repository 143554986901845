<template>

  <div class="header-panel w-full flex-col shadow shadow-md">

    <el-row class="flex items-center justify-between h-full py-2 px-4 with-max-width">

      <router-link to="/dashboard" class="logo-wrapper text-gray-500 simple-text">
        <img src="../../assets/images/logo.png" alt="logo.png" class="logo-img">
      </router-link>

      <div class="flex header-panel-items">

<!--        <div class="header-panel-item feedback ml-1">
          <router-link to="/contacts">
            <el-tooltip effect="primary" :content="$t('headerPanel.contacts')" placement="top" :open-delay="1000">
              <el-button class="rounded-full w-[50px] h-[50px] border-none p-2 text-2xl">
                <i class="fa-solid fa-headset text-base-color text-xl"/>
              </el-button>
            </el-tooltip>
          </router-link>
        </div>-->

        <div class="header-panel-item language ml-1">
          <el-tooltip effect="primary" :content="$t('headerPanel.language')" placement="top" :open-delay="1000">
            <el-dropdown trigger="click" :hide-on-click="false" placement="bottom-end" @command="changeLanguage">
              <div class="el-dropdown-link language-dropdown-link">
                <span class="language-image-wrapper">
                  <img src="../../assets/images/flags/uz.svg" alt="uz.svg" class="language-img" v-if="language === 'uz'">
                  <img src="../../assets/images/flags/ru.svg" alt="ru.svg" class="language-img" v-else-if="language === 'ru'">
                </span>
              </div>
              <el-dropdown-menu slot="dropdown" class="language-dropdown">
                <el-dropdown-item command="uz" class="language-item" :class="{'active': language === 'uz'}">
                  <img src="../../assets/images/flags/uz.svg" alt="uz">
                  <span>Узбекский</span>
                </el-dropdown-item>
                <el-dropdown-item command="ru" class="language-item" :class="{'active': language === 'ru'}">
                  <img src="../../assets/images/flags/ru.svg" alt="ru">
                  <span>Русский</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-tooltip>
        </div>

        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            <div class="header-panel-item log-out ml-1">
              <el-tooltip effect="primary" :content="getProfileContent()" placement="top">
                <el-button class="rounded-full w-[50px] h-[50px] border-none p-2 text-2xl">
                  <i class="fa-solid fa-user text-base-color text-xl"/>
                </el-button>
              </el-tooltip>
            </div>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="logOut">
              <span class="text-base">
                <i class="fa-solid fa-right-from-bracket text-base-color"/> {{$t('headerPanel.logOut')}}
              </span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

      </div>

    </el-row>

  </div>

</template>

<script>
import i18n from '@/i18n';
import {mapState} from "vuex";

export default {
  name: "HeaderPanel",
  computed: {
    language() {
      return this.$i18n.locale;
    },
    ...mapState(["keycloak", "profile"])
  },
  data() {
    return {
      languageCode: ''
    }
  },
  methods: {
    handleCommand(command) {
      console.log("handleCommand command = ", command)
      if(command === "logOut") {
        this.logOut()
      }
    },
    logOut() {
      this.$store.dispatch('logOut', this.keycloak);
    },
    changeLanguage(langCode) {
      this.languageCode = langCode;
      i18n.locale = langCode;
      localStorage.setItem('x_lang', langCode);
      document.querySelector('html').setAttribute('lang', langCode);
    },
    getProfileContent() {
      let a = ""
      if(this.profile.firstName) {
        a += this.profile.firstName
      }
      if(this.profile.lastName) {
        a += " " + this.profile.lastName
      }
      if(!this.profile.firstName && !this.profile.lastName) {
        a = this.profile?.username
      }
      return a
    }
  }
}
</script>

<style scoped>

</style>